import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Modal, Button, Row, Col } from "react-bootstrap";

//ACTIONS
import * as UserProfileActions from "../../../../../../../store/actions/userProfile";
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";
import * as BookingDataActions from "../../../../../../../store/actions/bookingData";

//COMPONENTS
import Timetable from "./roomTemplates/timetable";
import Diary from "./roomTemplates/diary";
import BookingCanvas from "./bookingCanvas";

//STYLES
import * as RoomViewStyles from "../styles/roomView";

function RoomView() {
    const params = useParams();
    const dispatch = useDispatch();
    const organisation = useSelector((state) => state.organisation);
    const userProfile = useSelector((state) => state.userProfile);
    const globalVars = useSelector((state) => state.globalVars);

    const [room, setRoom] = useState([]);
    const [layout, setLayout] = useState([]);
    const [weekBG, setWeekBG] = useState(moment());

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        const date = moment().startOf("week");
        setWeekBG(date);
        dispatch(GlobalVarsActions.UpdateTopControls_weekBG(date));
        CheckHoliday(date);
    }, []);

    useEffect(() => {
        const room = organisation.rooms.find((room) => room.uuid == params.id);
        const layoutData = organisation.layouts.find((layout) => layout.uuid == room.layout);

        setRoom(room);
        setLayout(layoutData);

        dispatch(GlobalVarsActions.UpdateRoomName(room.name));
        dispatch(GlobalVarsActions.UpdateRoomID(params.id));
        dispatch(GlobalVarsActions.UpdateRoomWeekSystem(room.weekSystem));
        dispatch(GlobalVarsActions.UpdateRoomLayoutData(layoutData));
        dispatch(GlobalVarsActions.UpdateRoomDayList(layoutData.days));

        UpdateRoomProfileSettings(room.uuid);
        dispatch(GlobalVarsActions.UpdateMenuID("rooms", params.id));
    }, [params.id]);

    function CheckHoliday(weekBG) {
        const date = moment(weekBG, "DD/MM/YYYY");
        const week = formatString(date.week()) + "-" + date.format("YY");
        let holidayTitle = "";

        for (const holiday of organisation.holidays) {
            if (holiday.weeks.includes(week)) {
                if (holiday.titleUUID.includes("w")) {
                    if (room.weekSystem) {
                        holidayTitle = holiday.name;
                        console.log(holiday.titleUUID);
                    }
                } else if (holiday.titleUUID.includes("h")) {
                    holidayTitle = "Holiday: " + holiday.name;
                }
                dispatch(GlobalVarsActions.UpdateRoomWeekUUID(holiday.titleUUID));
                break;
            }
        }

        dispatch(GlobalVarsActions.UpdateTopControls_Holiday(holidayTitle));
    }

    function formatString(time) {
        if (time.toString().includes("b")) {
            time = time.replace("b", "");

            if (time.toString().length == 1) {
                return "b0" + time;
            } else {
                return time;
            }
        } else {
            if (time.toString().length == 1) {
                return "0" + time;
            } else {
                return time;
            }
        }
    }

    function UpdateRoomProfileSettings(roomUUID) {
        const roomSettings = userProfile.rooms.find((settings) => settings.roomUUID == roomUUID);

        dispatch(UserProfileActions.UpdateRoom_View(roomSettings.prop_View == "true"));
        dispatch(UserProfileActions.UpdateRoom_Write(roomSettings.prop_Write == "true"));
        dispatch(UserProfileActions.UpdateRoom_Edit(roomSettings.prop_Edit == "true"));
        dispatch(UserProfileActions.UpdateRoom_Delete(roomSettings.prop_Delete == "true"));
        dispatch(UserProfileActions.UpdateRoom_Repeat(roomSettings.prop_Repeat == "true"));
    }

    function handleAdvanceWeek() {
        const newDate = moment(weekBG.add(1, "w"));
        setWeekBG(newDate);
        dispatch(GlobalVarsActions.UpdateTopControls_weekBG(newDate));
        CheckHoliday(newDate);
    }

    function handleGoBackWeek() {
        const week = moment(weekBG.subtract(1, "w"));

        let pastDate = moment();
        pastDate.startOf("week");

        pastDate.subtract(parseInt(userProfile.userSettings.weeks.goBackWeeks), "weeks");

        if (week < pastDate) {
            setModal({ heading: room.name, message: "You cannot go back previous from this week", open: true });
        } else {
            setWeekBG(week);
            dispatch(GlobalVarsActions.UpdateTopControls_weekBG(week));
            CheckHoliday(week);
        }
    }

    return (
        <div style={{ height: "100%", border: "2px solid Purple", display: "flex", flexDirection: "column" }}>
            <Row style={{ margin: "0" }}>
                <Col sm={4} style={{ ...RoomViewStyles.topControls, height: `${globalVars.body10thHeight * 1}px` }}>
                    <strong>Week Beginning: {weekBG.format("DD/MM/YYYY")}</strong>
                </Col>
                <Col sm={4} style={RoomViewStyles.topControls}>
                    <strong> {globalVars.topControls.holiday}</strong>
                </Col>
                <Col sm={4} style={RoomViewStyles.buttons}>
                    <div className="topSection_leftButton" onClick={handleGoBackWeek}>
                        <i className="fa-solid fa-angle-left"></i>{" "}
                    </div>
                    <div className="topSection_rightButton" onClick={handleAdvanceWeek}>
                        <i className="fa-solid fa-angle-right"></i>
                    </div>
                </Col>
            </Row>
            <Row style={{ margin: "0", flex: "1" }}>
                <Col>
                    <div style={{ height: "100%" }}>
                        {organisation.locked && <div style={RoomViewStyles.orgLocked}>Organisation Locked</div>}
                        {layout != null && layout.layout == "Timetable" && (
                            <div style={RoomViewStyles.timetableContiner}>
                                <Timetable
                                    orgID={params.orgID}
                                    layoutData={layout}
                                    roomName={room.mame}
                                    weekBG={weekBG}
                                    roomID={params.id}
                                    weekSystem={room.weekSystem}
                                />
                            </div>
                        )}
                        {layout != null && layout.layout == "Diary" && (
                            <div style={RoomViewStyles.diaryContainer}>
                                <div style={RoomViewStyles.diaryInnerContainer}>
                                    <Diary
                                        orgID={params.orgID}
                                        roomName={room.mame}
                                        weekBG={weekBG}
                                        roomID={params.id}
                                        layoutData={layout}
                                        weekSystem={room.weekSystem}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <BookingCanvas />
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default RoomView;
