export const session1_slot = {
    width: "10%",
    height: "60px"
};

export const session1_days = {
    width: "90%",
    height: "60px"
};

export const session2_slot = {
    width: "10%",
    height: "60px"
};

export const session2_days = {
    width: "45%",
    height: "60px"
};

export const session3_slot = {
    width: "10%",
    height: "60px"
};

export const session3_days = {
    width: "30%",
    height: "60px"
};

export const session4_slot = {
    width: "8%",
    height: "60px"
};

export const session4_days = {
    width: "23%",
    height: "60px"
};

export const session5_slot = {
    width: "10%",
    height: "60px"
};

export const session5_days = {
    width: "18%",
    height: "60px"
};

export const session6_slot = {
    width: "10%",
    height: "60px"
};

export const session6_days = {
    width: "15%",
    height: "60px"
};

export const session7_slot = {
    width: "9%",
    height: "60px"
};

export const session7_days = {
    width: "13%",
    height: "60px"
};

export const centered100 = {
    width: "100%",
    textAlign: "center"
};

export const sideBySideR = {
    float: "right"
};

export const centredWb = {
    padding: "15px",
    float: "left",
    fontWeight: "bold"
};

export const centredWbRed = {
    padding: "15px",
    float: "left",
    fontWeight: "bold"
};

export const layout = {
    textAlign: "center",
    border: "1px solid black"
};

export const loadingLayout = {
    border: "solid 1px black",
    fontWeight: "bold"
};

export const layoutSelected = {
    textAlign: "center",
    backgroundColor: "red",
    opacity: "0.1",
    border: "4px solid red"
};

export const singleSlot = {
    backgroundColor: "orange",
    cursor: "pointer"
};

export const repeatSlot = {
    backgroundColor: "green",
    color: "white",
    cursor: "pointer"
};

export const emptySlot = {
    color: "transparent"
};

export const currentDay = {
    backgroundColor: "red",
    color: "white"
};
