import * as Colors from "../../../globalStyles/colors";

export const mainBody = {
    // height: "16vh"
};

export const emptySpace = {
    height: "10%"
};

export const title = {
    // height: "90%",
    backgroundColor: Colors.primary,
    color: "white",
    textAlign: "center",
    padding: "5px",
    fontWeight: "bold"
};

export const topTitle = {
    fontSize: "2.5rem"
};

export const SubTitle = {
    fontSize: "2rem"
};

export const row = {
    marginRight: "0" //default is -15px,
};
export const clickableTitle = {
    color: "white",
    textAlign: "center"
};
