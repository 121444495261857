import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Route, Routes } from "react-router-dom";

//ACTIONS
import * as GlobalVarsActions from "../../../../store/actions/globalVars";

//STYLES
import * as OrgAdminPortalStyles from "./styles/orgAdminPortal";

//COMPONENTS
import MenuButton from "../components/adminButton";

import AdminUsers from "./pods/users/podRouter";
import AdminDepartments from "./pods/departmentSettings/podRouter";
import AdminLoginSettings from "./pods/loginSettings/podRouter";
import AdminRooms from "./pods/rooms/podRouter";
import AdminLayouts from "./pods/layoutSettings/podRouter";
import AdminWeeksHolidays from "./pods/weekSystemHolidays/podRouter";
import AdminUserProfiles from "./pods/userProfiles/podRouter";

function OrgAdminPortal(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [width, setWidth] = useState(0);
    const globalVars = useSelector((state) => state.globalVars);

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateMenuID("items", "orgAdmin"));
        dispatch(GlobalVarsActions.UpdateAdminMenuID(""));
        setWidth(15);
        console.log("hi admin");
    }, []);

    function handleMenuItemClick(followOnID) {
        navigate(`./${followOnID}`);
    }

    return (
        <div style={OrgAdminPortalStyles.body}>
            <div style={{ ...OrgAdminPortalStyles.menu, paddingTop: `3vw`, width: `${width}%`, transition: globalVars.menuTransition }}>
                <MenuButton title={"Users"} menu="admin" followOnID={"users"} click={handleMenuItemClick} />
                <MenuButton title={"Departments"} menu="admin" followOnID={"departments"} click={handleMenuItemClick} />
                <MenuButton title={"Login Settings"} menu="admin" followOnID={"loginSettings"} click={handleMenuItemClick} />
                <MenuButton title={"Rooms"} menu="admin" followOnID={"rooms"} click={handleMenuItemClick} />
                <MenuButton title={"Layouts"} menu="admin" followOnID={"layouts"} click={handleMenuItemClick} />
                <MenuButton title={"Week System / Holidays"} menu="admin" followOnID={"weeksHolidays"} click={handleMenuItemClick} />
                <MenuButton title={"User Profiles"} menu="admin" followOnID={"userProfiles"} click={handleMenuItemClick} />
            </div>
            <div style={OrgAdminPortalStyles.mainContent}>
                <Routes>
                    <Route path="/users/*" element={<AdminUsers />} />
                    <Route path="/departments/*" element={<AdminDepartments />} />
                    <Route path="/loginSettings/*" element={<AdminLoginSettings />} />
                    <Route path="/rooms/*" element={<AdminRooms />} />
                    <Route path="/layouts/*" element={<AdminLayouts />} />
                    <Route path="/weeksHolidays/*" element={<AdminWeeksHolidays />} />
                    <Route path="/userProfiles/*" element={<AdminUserProfiles />} />
                </Routes>
            </div>
        </div>
    );
}

export default OrgAdminPortal;
