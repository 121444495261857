export const body = {
    height: "100%",
    width: "100%"
};

export const menu = {
    height: "100%",
    // backgroundColor: "white",
    borderRight: "1px solid black",
    overflow: "hidden",
    float: "left"
};

export const mainContent = {
    width: "85%",
    height: "100%",
    float: "left"
    // border: "1px solid black"
};
