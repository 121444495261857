import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { useDispatch } from "react-redux";
import * as UserActions from "./store/actions/user";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "bootstrap/dist/css/bootstrap.css";

import "./globalStyles/customStyles.css";

import Nav from "./sections/NavBar/Nav";

import Home from "./sections/public/frontEnd/files/home";

import OrganisationPortal from "./sections/new/private/organisationPortal";

//HIGH-VIEW STUDIOS ADMINISTRATION
import AdminHome from "./sections/public/admin/files/home";
import AdminSignIn from "./sections/public/admin/files/signIn.js";
import AdminOrgRegister from "./sections/public/admin/files/registerOrganisation";
import AdminServices from "./sections/public/admin/files/pods/services";
import AdminOrgDetails from "./sections/public/admin/files/organisationDetails";

//ACTIONS
import * as GlobalVarsActions from "./store/actions/globalVars";

//STYLES
import * as OrganisationHomeStyles from "./sections/private/frontEnd/styles/organisationHome";
import * as AppStyles from "./appStyles";

<script src="https://kit.fontawesome.com/c37858cff5.js" crossOrigin="anonymous"></script>;

function App() {
    const dispatch = useDispatch();
    const [isLoaded, setLoaded] = useState(false);

    const heightOutput = document.querySelector("#height");
    const widthOutput = document.querySelector("#width");

    const mainBody = useRef(null);

    function reportWindowSize() {
        dispatch(GlobalVarsActions.UpdateMainBodyHeight(mainBody.current.offsetHeight));
    }

    window.onresize = reportWindowSize;

    useEffect(() => {
        setTimeout(() => {
            if (mainBody.current != null) {
                dispatch(GlobalVarsActions.UpdateMainBodyHeight(mainBody.current.offsetHeight));
            }
        }, 50);

        onOpen();
    }, []);

    //another useEffect with the force sign in const

    function onOpen() {
        Axios.get("/userLogin/auth", { withCredentials: true })
            .then((res) => {
                //console.log(res.data); //-------------
                const isAuth = res.data.auth;
                dispatch(UserActions.userUpdateAuth(isAuth));
                if (isAuth) {
                    if (res.data.role == "superAdmin") {
                        dispatch(UserActions.userUpdateName("High-ViewStudios"));
                        dispatch(UserActions.userUpdateRole("superAdmin"));
                    } else {
                        dispatch(UserActions.userUpdateUUID(res.data.user.uuid));
                        dispatch(UserActions.userUpdateName(res.data.user.displayName));
                        dispatch(UserActions.userUpdateRole(res.data.user.role));
                        dispatch(UserActions.userUpdateNew(res.data.user.new));
                        dispatch(UserActions.userUpdateRequestedPassword(res.data.user.requestedPassword));
                        dispatch(UserActions.userUpdateOrgID(res.data.user.orgID));
                        dispatch(UserActions.userUpdateUserDepartments(res.data.user.departments));
                        dispatch(UserActions.userUpdateSARequest(res.data.user.SARequest));
                        dispatch(UserActions.userUpdateProfiles(res.data.user.userProfiles));

                        //get organisation
                    }
                }
                setLoaded(true);
            })
            .catch((err) => {
                console.log("Authe" + err);
            });
    }

    return (
        <div style={{ ...AppStyles.backColor, height: "100vh" }}>
            {isLoaded ? (
                <Router>
                    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                        <div>
                            <Nav />
                        </div>
                        {mainBody != null && (
                            <div ref={mainBody} style={{ flex: "1" }}>
                                <Routes>
                                    <Route path={"/"} exact element={<Home />} />
                                    <Route path={"/org/:orgID/*"} element={<OrganisationPortal />} />
                                </Routes>
                            </div>
                        )}
                        {/* MAIN WEBSITE HOME */}
                        {/* ADMINISTRATION */}
                        {/* <Route path={"/administrator"} element={<AdminHome />} /> DO NOT TOUCH */}
                        {/* <Route path={"/administrator/signIn"} element={<AdminSignIn />} /> DO NOT TOUCH */}
                        {/* <Route path={"/administrator/organisationRegister"} element={<AdminOrgRegister />} /> */}
                        {/* <Route path={"/administrator/services"} element={<AdminServices />} /> */}
                        {/* <Route path={"/administrator/organisationDetails/:orgID"} element={<AdminOrgDetails />} /> */}
                        {/* ORGANISATION/USER */}
                        {/* <Route path={"/organisationNotFound"} element={<OrganisationNotFound />} /> */}
                        {/* coming direct from passport */}
                        {/* <Route path={"/org/:id/wrongLogin"} element={<WrongLogin />} /> */}
                        {/* <Route path={"/notConnected"} element={<NotConnected />} /> */}
                    </div>
                </Router>
            ) : (
                <div></div>
            )}
        </div>
    );
}

export default App;
