import React, { useState, useEffect } from "react";
import { Container, Dropdown, Row, Col, Card } from "react-bootstrap";
import Axios from "axios";
import { useSelector } from "react-redux";

//COMPONENTS
import BookingItem from "../../../../frontEnd/pods/bookings/files/bookingItem";

//STYLES
import * as OrganisationBookingsStyles from "../styles/organisationBookings";

function OrganisationBookings() {
    const user = useSelector((state) => state.user);
    const globalVars = useSelector((state) => state.globalVars);

    const [rooms, setRooms] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [users, setUsers] = useState([]);

    const [bookings, setBookings] = useState([]);

    const [filters, setFilters] = useState({
        room: { column: "roomID", filter: "no filter", name: "All" },
        user: { column: "user", filter: "no filter", name: "All" },
        createdBy: { column: "createdBy", filter: "no filter", name: "Any" },
        department: { column: "departmentID", filter: "no filter", name: "All" }
    });
    const [order, setOrder] = useState("Down");

    useEffect(() => {
        onLoad();
        handleFetchData(filters, order);
    }, []);

    function onLoad() {
        const data = { orgID: user.orgID };
        Axios.post("/orgAdmin/organisationBookings/getFilterData", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setRooms(data.rooms);
                    setDepartments(data.departments);
                    setUsers(data.users);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleFilterSelected(filterID, value, name) {
        const newFilters = { ...filters };

        newFilters[filterID].filter = value;
        newFilters[filterID].name = name;

        setFilters(newFilters);

        handleFetchData(newFilters, order);
    }

    function handleFetchData(filters, order) {
        console.log(order);
        const data = { orgID: user.orgID, filters, operator: "AND", order };
        Axios.post("/bookingsFilters/getBookings", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setBookings(data.bookings);
                } else {
                    console.log("Error: Test");
                }
            })
            .catch((err) => console.log(err));
    }

    function handleSortOrder() {
        let newOrder = "";
        if (order == "Up") {
            newOrder = "Down";
        } else {
            newOrder = "Up";
        }
        setOrder(newOrder);
        handleFetchData(filters, newOrder);
    }

    return (
        <div style={OrganisationBookingsStyles.body}>
            <Row>
                <Col style={{ height: `${globalVars.body10thHeight * 0.7}px`, paddingTop: "1vw" }}>
                    <h3>Organisation Bookings</h3>
                </Col>
            </Row>
            <Row>
                <Col style={{ height: `${globalVars.body10thHeight * 1.4}px`, textAlign: "center" }}>
                    Room:
                    <br />
                    <Dropdown>
                        <Dropdown.Toggle>{filters.room.name}</Dropdown.Toggle>
                        <Dropdown.Menu style={OrganisationBookingsStyles.dropdownItems}>
                            <Dropdown.Item onClick={handleFilterSelected.bind(this, "room", "no filter", "All")}>All</Dropdown.Item>
                            {rooms.map((room, index) => {
                                return (
                                    <Dropdown.Item key={index} onClick={handleFilterSelected.bind(this, "room", room.uuid, room.name)}>
                                        {room.name}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col style={{ height: `${globalVars.body10thHeight * 1.4}px`, textAlign: "center" }}>
                    User:
                    <br />
                    <Dropdown>
                        <Dropdown.Toggle>{filters.user.name}</Dropdown.Toggle>
                        <Dropdown.Menu style={OrganisationBookingsStyles.dropdownItems}>
                            <Dropdown.Item onClick={handleFilterSelected.bind(this, "user", "no filter", "All")}>All</Dropdown.Item>
                            {users.map((user, index) => {
                                return (
                                    <Dropdown.Item key={index} onClick={handleFilterSelected.bind(this, "user", user.uuid, user.displayName)}>
                                        {user.displayName}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col style={{ height: `${globalVars.body10thHeight * 1.4}px`, textAlign: "center" }}>
                    Created By:
                    <br />
                    <Dropdown>
                        <Dropdown.Toggle>{filters.createdBy.name}</Dropdown.Toggle>
                        <Dropdown.Menu style={OrganisationBookingsStyles.dropdownItems}>
                            <Dropdown.Item onClick={handleFilterSelected.bind(this, "createdBy", "no filter", "Any")}>Any</Dropdown.Item>
                            {users.map((user, index) => {
                                return (
                                    <Dropdown.Item key={index} onClick={handleFilterSelected.bind(this, "createdBy", user.uuid, user.displayName)}>
                                        {user.displayName}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col style={{ height: `${globalVars.body10thHeight * 1.4}px`, textAlign: "center" }}>
                    Department:
                    <br />
                    <Dropdown>
                        <Dropdown.Toggle>{filters.department.name}</Dropdown.Toggle>
                        <Dropdown.Menu style={OrganisationBookingsStyles.dropdownItems}>
                            <Dropdown.Item onClick={handleFilterSelected.bind(this, "department", "no filter", "All")}>All</Dropdown.Item>
                            {departments.map((department, index) => {
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={handleFilterSelected.bind(this, "department", department.uuid, department.name)}
                                    >
                                        {department.name}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col style={{ height: `${globalVars.body10thHeight * 1.3}px` }}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>Date</Col>
                                <Col>Room</Col>
                                <Col>User</Col>
                                <Col>Session</Col>
                                <Col>Booking Type</Col>
                                <Col style={OrganisationBookingsStyles.sortBox} onClick={handleSortOrder}>
                                    {order == "Up" ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col style={{ height: `${globalVars.body10thHeight * 7}px`, overflow: "scroll" }}>
                    {bookings.length == 0 ? (
                        <div>There are no booking with above criteria</div>
                    ) : (
                        <div>
                            {bookings.map((booking, index) => {
                                return <BookingItem key={index} admin={true} details={booking} />;
                            })}
                        </div>
                    )}
                </Col>
            </Row>
            {/* </div> */}
            {/* <div style={OrganisationBookingsStyles.list}>
                
            </div> */}
        </div>
    );
}

export default OrganisationBookings;
