import React from "react";
import { Row, Col } from "react-bootstrap";

//STYLES
import * as NavBarStyles from "./styles/navBarStyles";

function Nav() {
    return (
        <div style={NavBarStyles.mainBody}>
            <Row style={{ margin: "0" }}>
                <Col style={{ padding: "0" }}>
                    <br />
                    <div style={NavBarStyles.title}>
                        <div style={NavBarStyles.topTitle}>My STAFF</div>
                        <div style={NavBarStyles.SubTitle}>Space, Time, Flexible, Facilities</div>
                    </div>
                </Col>
            </Row>
            {/* <div style={NavBarStyles.emptySpace}></div> */}
        </div>
    );
}

export default Nav;
